.scanPackage__base {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(../Images/Background.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.scanPackage__title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 20%;

  &__text {
    width: 80%;
    letter-spacing: 1px;
    text-align: center;
    color: white;
    font-size: 3.5rem;
  }
}
.scanPackage__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;

  &__logo {
    max-width: 200px;
  }
}

.container {
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 200;
  font-size: 1.2rem;
  letter-spacing: 2px;
}

.image {
  height: 200px;
  width: 200px;
  background-color: #21b37d;
  border: solid 2px white;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #{&}__co2 {
    color: white;
    font-size: 2.2rem;
    height: 1px;
    padding-bottom: 10px;
    letter-spacing: 1px;
  }
  #{&}__img {
    width: 30%;
    margin-bottom: 30%;
  }
}

.home__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 74%;

  &__video {
    max-width: 80%;
    border-radius: 10px;
  }
}

.msg {
  margin-top: -15px;
  padding-bottom: 15px;
}

.packageScan__image__input {
  outline: none;
  border: 1px solid #394a54 !important;
  background: rgba(78, 58, 58, 0);
  color: rgba(255, 255, 255, 0);
  width: 0.1px;
}

.scanPackage__footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  &__logo {
    width: 350px;
  }
}

@media (max-width: 1023px) {
  .scanPackage__title {
    #{&}__text {
      font-size: 1.8rem;
    }
  }
}
@media (max-width: 767px) {
  .scanPackage__title {
    #{&}__text {
      font-size: 1.2rem;
    }
  }
  .scanPackage__image {
    #{&}__logo {
      height: 110px;
    }
  }

  .scanPackage__footer {
    padding: 15px;

    #{&}__logo {
      height: 30px;
    }
  }
}
