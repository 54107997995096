.scanned__base {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(../Images/Background.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.scanned__title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 20%;

  &__text {
    width: 80%;
    letter-spacing: 1px;
    text-align: center;
    color: white;
    font-size: 3.5rem;
  }
}
.scanned__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;

  &__logo {
    max-width: 200px;
  }
}

.packageScan__image__input {
  outline: none;
  background: rgba(78, 58, 58, 0);
  color: rgba(255, 255, 255, 0);
  width: 0.1px;
}

.scanned__footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  &__logo {
    width: 350px;
  }
}

@media (max-width: 767px) {
  .scanned__title {
    #{&}__text {
      font-size: 1.2rem;
    }
  }
  .scanned__image__container {
    #{&}__logo {
      height: 110px;
    }
  }

  .scanned__footer {
    padding: 15px;

    #{&}__logo {
      height: 30px;
    }
  }
}
